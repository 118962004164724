<template>
  <RevCard
    class="group h-full cursor-pointer overflow-hidden border text-left hover:shadow-long"
    :class="
      isProductCompared ? 'border-action-default-hi' : 'border-transparent'
    "
    data-qa="productCard"
    :hoverable="true"
    @click="handleCardClick"
  >
    <div
      ref="container"
      class="relative flex h-full flex-col pt-16"
      data-spec="product-card-content"
    >
      <div
        class="flex items-start gap-6 px-16 lg:min-h-28"
        :class="{ 'pb-8': tags.length > 0 }"
        data-spec="tags"
      >
        <RevTag
          v-for="tag in tags"
          :key="tag"
          :label="tag"
          variant="secondary"
        />
      </div>
      <ProductComparisonCheckbox
        v-if="isComparisonEnabled"
        class="absolute bottom-8 right-8 lg:bottom-auto lg:top-8"
        :is-checked="isProductCompared"
        :is-comparison-add-disabled="isComparisonAddProductDisabled"
        :is-responsive-comparison-enabled
        :product="$props"
        @change="emit('toggle-comparison-product', $props)"
      />
      <div class="flex p-16 pt-0" data-spec="main">
        <div
          class="flex grow flex-wrap content-start justify-center gap-8"
          data-spec="image+main-information"
        >
          <div
            class="flex flex-col items-center justify-center gap-4"
            data-spec="image+colors"
          >
            <RevIllustration
              :alt="imageAlt ?? i18n(translations.imageAlt, { label: title })"
              :height="128"
              sizes="128px"
              :src="imageSrc"
              :width="128"
            />

            <ColorSwatches v-if="colorSwatches" :colors="colorSwatches" />
          </div>

          <div
            class="flex grow basis-[159px] flex-col items-start gap-6"
            data-spec="product-info"
          >
            <div class="flex flex-col gap-2" data-spec="titles">
              <h2>
                <RevButtonBase ref="link" :to="url" @click="emit('link-click')">
                  <span class="body-1-bold line-clamp-2">
                    {{ title }}
                  </span>
                  <span
                    v-if="description"
                    class="text-static-default-low body-2 line-clamp-2"
                  >
                    {{ description }}
                  </span>
                </RevButtonBase>
              </h2>

              <span v-if="warranty" class="body-2">
                {{ i18n(translations.productCardWarranty, { warranty }) }}
                {{ i18n(translations.months) }}
              </span>

              <span v-if="grade" class="body-2">
                {{ i18n(translations.gradeDescription, { grade }) }}
              </span>
            </div>

            <ProductCardRatings
              v-if="reviewRating?.count && reviewRating?.average"
              :count="reviewRating.count"
              data-spec="rating"
              :score="reviewRating.average"
            />

            <div v-if="specifications && specifications.length > 0">
              <ProductSpecs :specifications />
            </div>
            <div v-if="price" data-spec="price-information">
              <div
                v-if="withStartingFrom"
                class="text-static-default-low caption"
              >
                {{ i18n(translations.startingFrom) }}
              </div>
              <ProductPrice
                :discounted-price="
                  discountedPrice && i18n.price(discountedPrice)
                "
                :price="i18n.price(price)"
                :price-new="priceNew && i18n.price(priceNew)"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="addToCartListener || withAddToCart" class="mt-auto p-16 pt-0">
        <AddToCartButton
          :id="listingId"
          :text="i18n(translations.addToCart)"
          @add-to-cart="addToCart"
        />
      </div>
    </div>
  </RevCard>
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, ref, toRefs } from 'vue'

import type { LinkInternal, Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { RevTag } from '@ds/components/Tag'

import type { ComparisonProduct, Scores } from '../../models/product'
import AddToCartButton from '../AddToCartButton/AddToCartButton.vue'
import ColorSwatches from '../ColorSwatches.vue'
import ProductCardRatings from '../ProductCardRatings.vue'
import ProductComparisonCheckbox from '../ProductComparison/ProductComparisonCheckbox.vue'
import ProductPrice from '../ProductPrice.vue'
import ProductSpecs from '../ProductSpecs.vue'

import translations from './ProductCard.translations'

export interface ProductCardProps {
  title: string
  description?: string
  imageSrc: string
  imageAlt?: string
  brand?: string
  model?: string
  id?: string
  category?: string
  categoryId?: number
  grade?: string
  url: string | LinkInternal
  tags?: Array<string>
  price: Price
  priceNew?: Price
  discountedPrice?: Price
  reviewRating?: {
    count: number
    average: number
  }
  listingId: string
  warranty?: number
  withStartingFrom?: boolean
  addingToCart?: boolean
  withAddToCart?: boolean
  specifications?: Array<{
    label: string
    values: string[]
  }>
  colorSwatches?: Array<{ name: string; value?: string }>
  isComparisonEnabled?: boolean
  scores?: Scores
  comparisonProducts?: ComparisonProduct[]
  isComparisonAddProductDisabled?: boolean
  isResponsiveComparisonEnabled?: boolean
}

const props = withDefaults(defineProps<ProductCardProps>(), {
  imageAlt: '',
  description: undefined,
  grade: '',
  tags: () => [],
  priceNew: undefined,
  discountedPrice: undefined,
  reviewRating: undefined,
  withStartingFrom: true,
  warranty: undefined,
  colorSwatches: () => [],
  comparisonProducts: () => [],
  addingToCart: false,
  withAddToCart: false,
  specifications: undefined,
  scores: undefined,
  brand: '',
  model: '',
  id: '',
  category: '',
  categoryId: undefined,
  isComparisonAddProductDisabled: false,
  isResponsiveComparisonEnabled: false,
})

const { listingId } = props
const { comparisonProducts } = toRefs(props)

const emit = defineEmits([
  'add-to-cart',
  'toggle-comparison-product',
  'link-click',
])

const container = ref<HTMLDivElement | null>(null)
const link = ref<typeof RevButtonBase | null>(null)

const i18n = useI18n()
defineExpose({ container })

const addToCartListener = getCurrentInstance()?.vnode.props?.onAddToCart

const isProductCompared = computed(() => {
  return (
    comparisonProducts.value?.some((p) => p.listingId === listingId) || false
  )
})

function addToCart({ status }: { status: string }) {
  emit('add-to-cart', { status })
}

function handleCardClick(e: Event) {
  const target = e.target as HTMLElement
  if (target.closest('a')) return
  link.value?.$el.click()
}
</script>
